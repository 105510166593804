import React from 'react'

function Experience() {
  return (
    <section id="resume" class="resume">
      <div class="container">

        <div class="section-title">
          <h2>Curriculum Vitae
</h2>
        </div>

        <div class="row">
          <div class="col-lg-6" data-aos="fade-up">
            <h3 class="resume-title">Sumary</h3>
            <div class="resume-item pb-0">
              <h4>John Zvorwadza</h4>
              <p><em>I'm passionate and highly skilled with IT, Technology, Coding & Software Applications. I work quickly and effectively under pressure and have fully up to date knowledge for writing clean code. Im constantly training myself with new coding applications and concepts.</em></p>
              <ul>
                <li>Newcastle upon Tyne</li>
              </ul>
            </div>

            <h3 class="resume-title">Education</h3>
            <div class="resume-item">
              <h4>Coding Traineeship</h4>
              <h5>2022</h5>
              <p><em>IT Career Switch, United Kingdom</em></p>
              <p>During this traineeship I had to learn and demonstrate a very competent level of the skills needed by a full stack developer.</p>
            </div>
            <div class="resume-item">
              <h4>GCSE's</h4>
              <h5>2008 - 2012</h5>
              <p><em>Zengeza 2 High, Chitungwiza, Zimbabwe</em></p>
            </div>
          </div>
          <div class="col-lg-6" data-aos="fade-up" data-aos-delay="100">
            <h3 class="resume-title">Experience</h3>
            <div class="resume-item">
              <h4>Self employeed</h4>
              <h5>2019 - 2021</h5>
              <p><em>Get Media News</em></p>
              <p>Get Media News was an online news company I started in 2019, the website got most of it's traffic from facebook pages and groups.
                The company now has about 100 000 followers on it's social media pages.</p>
              <ul>
                <li>Selecting the right Ad Networks to work with.</li>
                <li>Promoting the pages and groups to gain more reach.</li>
                <li>Managing the website</li>
              </ul>
            </div>
            <div class="resume-item">
              <h4>Customer Assistant</h4>
              <h5>2016 - 2022</h5>
              <p><em>Tesco, United Kingdom</em></p>
            </div>
          </div>
        </div>
        <br/>
        <p><a target="_blank" class="btn-primary" href='JohnZvorwadzaCV.pdf'>Download Curriculum Vitae
</a></p>

      </div>
    </section>

  )
}

export default Experience