import React from 'react'
import Portfolio from './Portfolio'

function Main() {
  return (<div> 
     <h1>Portfolio</h1>
      <Portfolio/>
      </div>)
}

export default Main